<template>
  <div>
    <ContractList />
  </div>
</template>

<script>
import ContractList from "@/components/ContractList";

export default {
  name: "ContractsView",
  components: { ContractList },
}
</script>
