<script>
import SPFGrid from '@/components/List/SPFGrid.vue';
import ContractDataService from "@/services/ContractDataService";

export default {
  name: 'ContractList',
  extends: SPFGrid,
  props: {
    apiSlug: { default: 'contracts' },
    vueSlug: { default: 'contract' }
  },
  data() {
    return {
      dataService: ContractDataService,
      fieldConfig: ContractDataService.getGridConfig(),
    }
  }
}
</script>
